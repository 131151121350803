import ManagedDrawer from '@components/common/drawer/managed-drawer'
import ManagedModal from '@components/common/modal/managed-modal'
import { ManagedUIContext } from '@contexts/ui.context'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect, useRef } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import { ToastContainer } from 'react-toastify'
// import { ReactQueryDevtools } from 'react-query/devtools';
import { DefaultSeo } from '@components/seo/default-seo'
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation } from 'next-i18next'
import { usePreserveScroll } from '../utils/scroll-history'
import axios from 'axios'
import { GoogleTagManager } from '@next/third-parties/google'

// external
import 'react-toastify/dist/ReactToastify.css'

// base css file
import '@assets/css/custom-plugins.css'
import '@assets/css/globals.scss'
import '@assets/css/rc-drawer.css'
import '@assets/css/scrollbar.css'
import '@assets/css/swiper-carousel.scss'
import '@assets/css/instantsearch.css'
import { getDirection } from '@utils/get-direction'
import Script from 'next/script'

function Noop({ children }: PropsWithChildren<{}>) {
  return <>{children}</>
}

const CustomApp = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) => {
  const queryClientRef = useRef<any>()
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient()
  }
  const router = useRouter()
  const dir = getDirection(router.locale)
  let checked = false
  const isComingSoonEnabled =
    process.env.NEXT_PUBLIC_COMING_SOON_STATUS === 'enabled'
  useEffect(() => {
    document.documentElement.dir = dir

    // if (isComingSoonEnabled && router.pathname !== '/') {
    //   router.push('/');
    // }

    // // GEOLOCATION START
    // const options = {
    //   method: 'GET',
    //   url: 'https://geolocation-db.com/json/0daad5e0-82e7-11ee-92e0-f5d620c7dcb4',
    // };

    // const user_country = sessionStorage.user_country;
    // if (!checked && (!user_country || user_country === 'undefined')) {
    //   checked = true;
    //   try {
    //     axios.request(options).then((response) => {
    //       sessionStorage.user_country = response.data.country_name;
    //       if (response.data.country_name !== 'Canada') {
    //         if (
    //           confirm(
    //             `You seem to be in ${response.data.country_name}. Would you like to be redirected to the US site?`
    //           )
    //         ) {
    //           router.push('https://www.foodservicedirect.com');
    //         }
    //       }
    //     });
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }
    // // GEOLOCATION END
  }, [dir])
  const Layout = (Component as any).Layout || Noop
  usePreserveScroll()

  return (
    <>
      <Script
        defer
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&libraries=places`}
      />
      <Script
        src="https://js.afterpay.com/afterpay-1.x.js"
        data-analytics-enabled
        async
      ></Script>
      {/* <Script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=7546d5a8-a3cd-4869-a767-a0da28d2511d"
      >
        {' '}
      </Script> */}
      <Script
        defer
        src="https://www.paypalobjects.com/api/checkout.js"
      ></Script>
      <Script src="https://js.braintreegateway.com/web/3.101.0/js/client.min.js" />
      <Script src="https://js.braintreegateway.com/web/3.101.0/js/apple-pay.min.js" />
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GOOGLE_GTM} />
      <SessionProvider session={session}>
        <QueryClientProvider client={queryClientRef.current}>
          {/* @ts-ignore */}
          {/* <Hydrate state={pageProps.dehydratedState}> */}
          <ManagedUIContext>
            <>
              <DefaultSeo />
              <Layout pageProps={pageProps}>
                <Component {...pageProps} key={router.route} />
              </Layout>
              <ToastContainer />
              <ManagedModal />
              <ManagedDrawer />
            </>
          </ManagedUIContext>
          {/* </Hydrate> */}
          {/* <ReactQueryDevtools /> */}
        </QueryClientProvider>
      </SessionProvider>
    </>
  )
}

export default appWithTranslation(CustomApp)
