import { PAYMENT_METHOD } from '@constants/index'
import { toast } from 'react-toastify'
import { PaymentMethod } from 'src/interfaces/checkout'
import { IShppingInfoPayload } from '@contexts/cart/cart.utils'
import { PickupAddress } from '@contexts/checkout/checkout.utils'

const isCreditCardExpired = (expirationDate: string) => {
  // Validate the format using a regular expression
  const formatRegex = /^(0[1-9]|1[0-2])\/\d{2}$/
  if (!formatRegex.test(expirationDate)) {
    // Invalid format
    return true
  }

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear() % 100 // Get last two digits of the current year
  const currentMonth = currentDate.getMonth() + 1 // Months are zero-indexed
  const [inputMonth, inputYear] = expirationDate.split('/').map(Number)

  // Check if the credit card is expired
  if (
    inputYear < currentYear ||
    (inputYear === currentYear && inputMonth < currentMonth)
  ) {
    return true // Card is expired
  }

  return false // Card is not expired
}

const getSaveInAddressBook = (
  sameAsShipping: boolean,
  addressform: boolean,
  billingAddressform: boolean,
) => {
  if (sameAsShipping && addressform) {
    return 1
  }
  if (sameAsShipping && !addressform) {
    return 0
  }
  if (!sameAsShipping && billingAddressform) {
    return 1
  }
  if (!sameAsShipping && !billingAddressform) {
    return 0
  }
}

const checkPOBoxAddress = (addressLineOne: string) => {
  const address1 = addressLineOne.toUpperCase()
  if (
    address1.includes('PO BOX') ||
    address1.includes(' BOX ') ||
    address1.includes('P.O')
  ) {
    toast.error(
      'Looks you are entering a PO Box, we cannot ship to those so please provide a physical address',
      {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toast-error',
      },
    )
    return false
  }
  return true
}

const disablePlaceOrderButton = (
  currentPaymentMethod: PaymentMethod,
  paypalNonce: string,
  applePayNonce: string,
) => {
  if (
    currentPaymentMethod?.code === PAYMENT_METHOD.BRAINTREE_PAYPAL &&
    paypalNonce
  )
    return false
  else if (
    currentPaymentMethod?.code === PAYMENT_METHOD.BRAINTREE_PAYPAL &&
    !paypalNonce
  )
    return true
  else if (
    currentPaymentMethod?.code === PAYMENT_METHOD.BRAINTREE_APPLEPAY &&
    applePayNonce
  )
    return false
  else if (
    currentPaymentMethod?.code === PAYMENT_METHOD.BRAINTREE_APPLEPAY &&
    !applePayNonce
  )
    return true
  else return false
}

const seperateItems = (items: any, itemsSections: any) => {
  let clickCollectItems: any = []
  let deliveryItems: any = []
  items.map((item: any) => {
    if (item.click_and_collect === 'Yes') clickCollectItems.push(item)
    else deliveryItems.push(item)
  })
  return [
    { ...itemsSections[0], items: clickCollectItems },
    { ...itemsSections[1], items: deliveryItems },
  ]
}

const getPickUpAddress = (pickupAddress: PickupAddress, email?: string) => {
  const { address_details, country, region } = pickupAddress
  let payload: IShppingInfoPayload = {
    country_id: country.country_code,
    postcode: address_details.postal_code,
    city: address_details.city,
    email: email,
    firstname: address_details.first_name,
    lastname: address_details.last_name,
    region_code: region.region_code,
    region: region.region_name,
    street: [address_details.address_line_1, address_details.address_line_2],
    telephone: address_details.phone,
    saveInAddressBook: 0,
  }
  return { address: payload }
}

const determineImagePath = (paymentMethodCode: string, checked: boolean) => {
  switch (paymentMethodCode) {
    case PAYMENT_METHOD.BRAINTREE_CREDITCARD:
      return checked
        ? '/assets/images/payment/credit-card-white-icon.svg'
        : '/assets/images/payment/credit-card-black-icon.svg'
    case PAYMENT_METHOD.BRAINTREE_PAYPAL:
      return checked
        ? '/assets/images/payment/paypal-white-icon.svg'
        : '/assets/images/payment/paypal-black-icon.svg'
    case PAYMENT_METHOD.BRAINTREE_APPLEPAY:
      return checked
        ? '/assets/images/payment/applepay-white-icon.svg'
        : '/assets/images/payment/applepay-black-icon.svg'
    case PAYMENT_METHOD.AFTERPAY:
      return checked
        ? '/assets/images/payment/afterpay-white-icon.svg'
        : '/assets/images/payment/afterpay-black-icon.svg'

    default:
      return null
  }
}

const getDefaultShowAddresses = (addresses, type) => {
  let userAddresses = [...addresses]
  if (addresses?.length < 5) return userAddresses

  let defaultAddressIndex = userAddresses?.findIndex(
    (address: any) => address[type] != null,
  )

  if (defaultAddressIndex === -1) defaultAddressIndex = 0

  const defaultAddress = userAddresses?.splice(defaultAddressIndex, 1)

  return [...defaultAddress, ...userAddresses.slice(0, 3)]
}

const checkoutServices = {
  isCreditCardExpired,
  getSaveInAddressBook,
  checkPOBoxAddress,
  disablePlaceOrderButton,
  seperateItems,
  getPickUpAddress,
  determineImagePath,
  getDefaultShowAddresses,
}

export default checkoutServices
