import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { cartReducer, State, initialState } from './cart.reducer'
import {
  ICartPayload,
  IShippingMethod,
  IShppingInfoPayload,
  Item,
  getItem,
  inStock,
} from './cart.utils'
import { useLocalStorage } from '@utils/use-local-storage'
import ENDPOINT from '@utils/restendpoints'
import { getSession, signIn, useSession } from 'next-auth/react'
import { UserContext } from '@contexts/user/user.context'
import { IUser } from '@contexts/user/user.utils'
import { useLazyRestApi } from '@hooks/useRestApi'
import cartServices from '@services/cartservices'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { useTranslation } from 'next-i18next'
import ga4Services from '@services/ga4services'
import { CARRIER_CODE_PUROLATOR } from '@constants/index'
import StoreLocationIcon from '@components/icons/store-location'
import { FiTruck } from 'react-icons/fi'
import checkoutServices from '@services/checkoutservices'
import { ITEM_ADDTOCART_MAX_LIMIT } from '../../constants'

interface CartProviderState extends State {
  addItemToCart: (item: Item, quantity: number) => void
  updateCartItem: (item: Item, qty: number) => void
  clearItemFromCart: (id: Item['id']) => void
  getItemFromCart: (id: Item['id']) => any | undefined
  isInCart: (id: Item['id']) => boolean
  isInStock: (id: Item['id']) => boolean
  resetCart: () => void
  logoutResetCart: () => void
  callShippingInformation: () => void
  shippingMethods: []
  availablePaymentMethods: []
  selectedShippingMethod: any
  loadingShipping: boolean
  handleShippingMethodSelection: (params: any) => void
  setLoadingShipping: any
  updatingShipping: any
  setUpdatingShipping: any
  setAvailablePaymentMethods: any
  setShippingMethods: Dispatch<SetStateAction<any>>
  setSelectedShippingMethod: Dispatch<SetStateAction<any>>
  groupItems: any
  isOnlyPickUp: boolean
  isPartial: boolean
  isOnlyDelivery: boolean
}
export const cartContext = createContext<CartProviderState | undefined>(
  undefined,
)

cartContext.displayName = 'CartContext'

const MULTIPLE_SHIPPING_CODE = 'rbmultipleshipping'

export const useCart = () => {
  const context = useContext(cartContext)
  if (context === undefined) {
    throw new Error(`useCart must be used within a CartProvider`)
  }
  return context
}
let addToCartPayload: ICartPayload
export function CartProvider(props: PropsWithChildren<any>) {
  const {
    cart,
    isGuest,
    user,
    shippingInformation,
    billingInformation,
    sameAsShipping,
    setCartLoader,
    getCart,
    setCart,
    cartId,
    logInUserItems,
  } = useContext(UserContext) as IUser
  const { t: translate } = useTranslation('common')
  const [shippingMethods, setShippingMethods] = useState([])
  const [loadingShipping, setLoadingShipping] = useState(false)
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState([])
  const [updatingShipping, setUpdatingShipping] = useState(false)
  const [selectedShippingMethod, setSelectedShippingMethod] = useState<any>()
  const [isOnlyPickUp, setIsOnlyPickup] = useState(false)
  const [isPartial, setIsPartial] = useState(false)
  const [isOnlyDelivery, setIsOnlyDelivery] = useState(false)

  const [groupItems, setGroupItems] = useState([
    {
      title: 'Pickup from Store ',
      icon: (color = 'white') => (
        <StoreLocationIcon width="18" height="18" color={color} />
      ),
      items: [],
    },
    {
      title: 'Delivery ',
      icon: (color = 'white') => <FiTruck fontSize={20} color={color} />,
      items: [],
    },
  ])

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem('selectedMethod'))
        setSelectedShippingMethod(localStorage.getItem('selectedMethod'))
    } else {
      console.error('localStorage is not available in this environment')
    }
  }, [])

  const [coldPackFee, setColdPackFee] = useState(0)
  const [trigger, setTrigger] = useState(false)
  const { data: session }: any = useSession()
  const router = useRouter()
  const isCartPage = router.pathname === '/cart'
  const isCheckoutPage = router.pathname === '/checkout'

  const [savedCart, saveCart] = useLocalStorage(
    `borobazar-cart`,
    JSON.stringify(initialState),
  )
  const [state, dispatch] = useReducer(cartReducer, JSON.parse(savedCart!))

  useEffect(() => {
    if (state.items.length)
      setGroupItems(checkoutServices.seperateItems(state.items, groupItems))
  }, [state.items])

  useEffect(() => {
    if (groupItems[0].items.length > 0 && groupItems[1].items.length === 0) {
      setIsOnlyPickup(true)
      setIsPartial(false)
      setIsOnlyDelivery(false)
    } else if (
      groupItems[0].items.length === 0 &&
      groupItems[1].items.length > 0
    ) {
      setIsOnlyPickup(false)
      setIsPartial(false)
      setIsOnlyDelivery(true)
    } else if (
      groupItems[0].items.length > 0 &&
      groupItems[1].items.length > 0
    ) {
      setIsOnlyPickup(false)
      setIsPartial(true)
      setIsOnlyDelivery(true)
    }
  }, [groupItems])

  useEffect(() => {
    setUpdatingShipping(false)
  }, [cart])

  const estimateShippingPayload = () => {
    let payload: IShppingInfoPayload = {
      country_id: shippingInformation?.country_id,
      postcode: shippingInformation?.postcode,
      region: shippingInformation?.region_code
        ? shippingInformation?.region_code
        : shippingInformation?.region,
      extension_attributes: shippingInformation.extension_attributes,
    }
    if (user) payload.email = user.email
    return { address: payload }
  }

  useEffect(() => {
    saveCart(JSON.stringify(state))
  }, [state, saveCart])

  const [updateCart] = useLazyRestApi({
    url: ENDPOINT[isGuest ? 'UPDATE_GUEST_CART' : 'UPDATE_CUSTOMER_CART'],
    method: 'put',
    onComplete: async () => {
      getCart()
    },
  })

  const [deleteCartItem] = useLazyRestApi({
    url: ENDPOINT[isGuest ? 'DELETE_GUEST_CART' : 'DELETE_CUSTOMER_CART'],
    method: 'delete',
    onComplete: async () => {
      await callShippingInformation()
    },
  })

  const [deleteCart] = useLazyRestApi({
    url: ENDPOINT[
      isGuest ? 'DELETE_GUEST_ALL_CART' : 'DELETE_CUSTOMER_ALL_CART'
    ],
    method: 'delete',
  })

  useEffect(() => {
    if (logInUserItems?.length)
      dispatch({ type: 'SET_LOGIN_USER_ITEMS', items: logInUserItems })
  }, [logInUserItems])

  const addItemToCart = async (item: Item, quantity: number) => {
    item.click_and_collect = item?.isClickCollect ? 'Yes' : 'No'

    let currentItem: Item = state?.items?.find(
      (checkItem) => checkItem.sku === item.sku,
    ) as Item

    let checkQuantity = currentItem
      ? (currentItem.quantity ?? 0) + quantity
      : quantity

    if (checkQuantity > ITEM_ADDTOCART_MAX_LIMIT) {
      toast.error(
        translate('text-max-qty-message') + ' ' + ITEM_ADDTOCART_MAX_LIMIT,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toast-error',
        },
      )
      return true
    }
    dispatch({ type: 'ADD_ITEM_WITH_QUANTITY', item, quantity })
    toast(translate('text-cart-added'), {
      progressClassName: 'fancy-progress-bar',
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: { marginTop: '50px' },
    })
    const authSession: any = await getSession()
    setTrigger(true)
    const payload: ICartPayload = {
      cartItem: {
        qty: quantity,
        quote_id: authSession?.isGuest ? authSession?.cart : cartId?.id,
        sku: item?.sku,
        vendor_id: item?.vendor_id
          ? item?.vendor_id
          : process.env.NEXT_PUBLIC_VENDOR_ID,
        click_and_collect: item?.isClickCollect ? 1 : 0,
      },
    }
    ga4Services.addToCart([
      {
        id: item?.id,
        formattedName: item?.formattedName,
        quantity,
        category: item?.category,
        category_l2: item?.category_l2,
        category_l3: item?.category_l3,
        category_l4: item?.category_l4,
        brand: item?.brand,
        price: item?.price,
      },
    ])
    addToCartPayload = payload
  }

  const updateCartItem = async (item: Item, qty: number) => {
    const { id } = item
    if (qty) {
      item.quantity = qty
      dispatch({ type: 'UPDATE_ITEM', id, item })
      await updateCart({
        cartItem: {
          qty: qty,
          quote_id: cartId?.id ? cartId?.id : session?.cart,
        },
        itemId: id,
      })
    }
  }

  const clearItemFromCart = (id: Item['id']) => {
    dispatch({ type: 'REMOVE_ITEM', id })
    deleteCartItem({ itemId: id })
    // callShippingInformation();
  }

  const isInCart = useCallback(
    (id: Item['id']) => !!getItem(state.items, id),
    [state.items],
  )
  const getItemFromCart = useCallback(
    (id: Item['id']) => getItem(state.items, id),
    [state.items],
  )
  const isInStock = useCallback(
    (id: Item['id']) => inStock(state.items, id),
    [state.items],
  )
  const resetCart = async () => {
    dispatch({ type: 'RESET_CART' })
    await deleteCart({})
  }
  const logoutResetCart = () => {
    dispatch({ type: 'RESET_CART' })
  }

  const [
    addToCartApi,
    { data: cartData, error: AddtocartError, body: apiPayload },
  ] = useLazyRestApi({
    url: ENDPOINT[isGuest ? 'GUEST_CART' : 'ADD_TO_CART'],
    method: 'post',
  })

  useEffect(() => {
    if (AddtocartError) {
      const id = apiPayload?.cartItem?.sku
      dispatch({
        type: 'REMOVE_ITEM_OR_QUANTITY',
        id,
        quantity: apiPayload?.cartItem?.qty,
      })
      // dispatch({ type: 'REMOVE_ITEM', id });
      toast.error('Item not added', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toast-error',
        autoClose: 2000,
      })
    }
  }, [AddtocartError])

  useEffect(() => {
    if (trigger && (session?.token || session?.isGuest)) {
      addToCartApi(addToCartPayload)
      setTrigger(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, session])

  const callShippingInformation = async () => {
    if (isCheckoutPage && shippingInformation?.postcode) {
      setCartLoader(true)
      try {
        let shippingMethodsResponse =
          await cartServices.estimatedShippingMethods(
            isGuest,
            estimateShippingPayload(),
          )
        if (shippingMethodsResponse) setLoadingShipping(false)
        setShippingMethods(shippingMethodsResponse)

        let firstMultipleShippingMethod
        if (localStorage.getItem('selectedMethod')) {
          firstMultipleShippingMethod = shippingMethodsResponse.find(
            (method: { carrier_code: string; method_code: string }) =>
              localStorage.getItem('selectedMethod') ===
              `${method?.carrier_code}_${method?.method_code}`,
          )
        }
        if (!firstMultipleShippingMethod) {
          firstMultipleShippingMethod = shippingMethodsResponse.find(
            (method: { carrier_code: string; method_code: string }) =>
              method.carrier_code === 'rbmultipleshipping',
          )
        }

        setSelectedShippingMethod(firstMultipleShippingMethod)
        localStorage.setItem(
          'selectedMethod',
          `${firstMultipleShippingMethod?.carrier_code}_${firstMultipleShippingMethod?.method_code}`,
        )

        let shippingAddressFromQuoteResponse: any

        if (shippingMethodsResponse.length > 0) {
          cartServices.setSelectedPostalCode(shippingInformation.postcode)
          const payload = cartServices.createShippingInfoPayload({
            shippingInformation,
            billingInformation,
            firstMultipleShippingMethod: firstMultipleShippingMethod,
            sameAsShipping,
            user,
          })
          shippingAddressFromQuoteResponse =
            await cartServices.shippingAddressFromQuote(isGuest, payload)
        }
        if (shippingAddressFromQuoteResponse) {
          setAvailablePaymentMethods(
            shippingAddressFromQuoteResponse?.payment_methods,
          )
          setCart(shippingAddressFromQuoteResponse)
          setCartLoader(false)
          getCart()
        }
      } catch (error) {
        setCartLoader(false)
        console.error('callShippingInformation:', error)
      }
    } else {
      setCart()
      setCartLoader(false)
    }
  }

  const handleShippingMethodSelection = async (
    shippingMethod: IShippingMethod,
  ) => {
    setCartLoader(true)
    try {
      setUpdatingShipping(true)
      const multipleShippingMethods = shippingMethods?.filter(
        (availableMethod: { carrier_code: string }) =>
          availableMethod?.carrier_code === MULTIPLE_SHIPPING_CODE,
      )

      const selectedShippingMethodCode = `${shippingMethod?.carrier_code}_${shippingMethod?.method_code}`

      const firstMultipleShippingMethod: any = multipleShippingMethods.find(
        (method: { method_code: string }) =>
          method.method_code === selectedShippingMethodCode,
      )

      setSelectedShippingMethod(firstMultipleShippingMethod)
      localStorage.setItem(
        'selectedMethod',
        `${firstMultipleShippingMethod?.carrier_code}_${firstMultipleShippingMethod?.method_code}`,
      )
      if (shippingMethod?.carrier_code === CARRIER_CODE_PUROLATOR) {
        localStorage.setItem(
          'additionalShippingData',
          JSON.stringify(shippingMethod?.extension_attributes),
        )
      } else {
        localStorage.removeItem('additionalShippingData')
      }

      let shippingAddressFromQuoteResponse: any

      cartServices.setSelectedPostalCode(shippingInformation.postcode)
      const payload = cartServices.createShippingInfoPayload({
        shippingInformation,
        billingInformation,
        firstMultipleShippingMethod,
        sameAsShipping,
        user,
      })
      shippingAddressFromQuoteResponse =
        await cartServices.shippingAddressFromQuote(isGuest, payload)

      if (shippingAddressFromQuoteResponse) {
        setAvailablePaymentMethods(
          shippingAddressFromQuoteResponse?.payment_methods,
        )
        setCart(shippingAddressFromQuoteResponse)
        setCartLoader(false)
        getCart()
      }
    } catch (error) {
      setCartLoader(false)
      console.error('handleShippingMethodSelection:', error)
    }
  }

  const value = useMemo(
    () => ({
      ...state,
      addItemToCart,
      updateCartItem,
      clearItemFromCart,
      getItemFromCart,
      isInCart,
      isInStock,
      resetCart,
      logoutResetCart,
      callShippingInformation,
      shippingMethods,
      availablePaymentMethods,
      selectedShippingMethod,
      loadingShipping,
      handleShippingMethodSelection,
      setLoadingShipping,
      updatingShipping,
      setAvailablePaymentMethods,
      setShippingMethods,
      setSelectedShippingMethod,
      groupItems,
      isOnlyPickUp,
      isPartial,
      isOnlyDelivery,
    }),
    [
      addItemToCart,
      updateCartItem,
      getItemFromCart,
      isInCart,
      isInStock,
      state,
      shippingMethods,
      getCart,
      session,
      setAvailablePaymentMethods,
      setShippingMethods,
      setSelectedShippingMethod,
      groupItems,
      isOnlyPickUp,
      isPartial,
      isOnlyDelivery,
    ],
  )
  return <cartContext.Provider value={value} {...props} />
}
